import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Button } from 'components/common';
import dev from 'assets/illustrations/skills.svg';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';

export const Skills = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper id="about">
      <SkillsWrapper as={Container}>
        <Thumbnail>
          <img src={dev} alt="Image" />
        </Thumbnail>
        <Details theme={theme}>
          <h1>O mnie</h1>
          <p>Jestem Frontend Developerem z wieloletnim doświadczeniem.</p>
          <p>Aktualnie używam/pracuję na:</p>
          <ul>
            <li>React</li>
            <li>VUE</li>
            <li>Wordpress (themes & plugins)</li>
            <li>TYPO3 (frontend)</li>
            <li>Magento 2 (frontend)</li>
          </ul>
          <Button as={AnchorLink} href="#contact">
            Napisz do mnie
          </Button>
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};
